import { default as _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta } from "/opt/buildhome/repo/pages/[slug].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta } from "/opt/buildhome/repo/pages/benchmark.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47contact_46vueMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47faq_46vueMeta } from "/opt/buildhome/repo/pages/faq.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta } from "/opt/buildhome/repo/pages/gallery.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47home_46vueMeta } from "/opt/buildhome/repo/pages/home.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47index_46vueMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta } from "/opt/buildhome/repo/pages/news/[slug].vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta } from "/opt/buildhome/repo/pages/news/index.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta } from "/opt/buildhome/repo/pages/nxtgen.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47playground_46vueMeta } from "/opt/buildhome/repo/pages/playground.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta } from "/opt/buildhome/repo/pages/sign-up.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta } from "/opt/buildhome/repo/pages/spacecraft-fleet.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta } from "/opt/buildhome/repo/pages/spaceflight-experience.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta } from "/opt/buildhome/repo/pages/spaceport-america.vue?macro=true";
import { default as _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta } from "/opt/buildhome/repo/pages/webgl-sandbox.vue?macro=true";
export default [
  {
    name: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.name ?? "slug___en",
    path: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.path ?? "/:slug",
    file: "/opt/buildhome/repo/pages/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.name ?? "slug___fr",
    path: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.path ?? "/fr/:slug",
    file: "/opt/buildhome/repo/pages/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.name ?? "slug___es",
    path: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.path ?? "/es/:slug",
    file: "/opt/buildhome/repo/pages/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.name ?? "slug___it",
    path: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.path ?? "/it/:slug",
    file: "/opt/buildhome/repo/pages/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.name ?? "slug___de",
    path: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.path ?? "/de/:slug",
    file: "/opt/buildhome/repo/pages/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.name ?? "benchmark___en",
    path: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.path ?? "/benchmark",
    file: "/opt/buildhome/repo/pages/benchmark.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.name ?? "benchmark___fr",
    path: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.path ?? "/fr/benchmark",
    file: "/opt/buildhome/repo/pages/benchmark.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.name ?? "benchmark___es",
    path: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.path ?? "/es/benchmark",
    file: "/opt/buildhome/repo/pages/benchmark.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.name ?? "benchmark___it",
    path: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.path ?? "/it/benchmark",
    file: "/opt/buildhome/repo/pages/benchmark.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.name ?? "benchmark___de",
    path: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.path ?? "/de/benchmark",
    file: "/opt/buildhome/repo/pages/benchmark.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47benchmark_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact___en",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact___fr",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/fr/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact___es",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/es/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact___it",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/it/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.name ?? "contact___de",
    path: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.path ?? "/de/contact",
    file: "/opt/buildhome/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.name ?? "faq___en",
    path: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.path ?? "/faq",
    file: "/opt/buildhome/repo/pages/faq.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.name ?? "faq___fr",
    path: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.path ?? "/fr/faq",
    file: "/opt/buildhome/repo/pages/faq.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.name ?? "faq___es",
    path: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.path ?? "/es/faq",
    file: "/opt/buildhome/repo/pages/faq.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.name ?? "faq___it",
    path: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.path ?? "/it/faq",
    file: "/opt/buildhome/repo/pages/faq.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.name ?? "faq___de",
    path: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.path ?? "/de/faq",
    file: "/opt/buildhome/repo/pages/faq.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.name ?? "gallery___en",
    path: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.path ?? "/gallery",
    file: "/opt/buildhome/repo/pages/gallery.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.name ?? "gallery___fr",
    path: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.path ?? "/fr/gallery",
    file: "/opt/buildhome/repo/pages/gallery.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.name ?? "gallery___es",
    path: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.path ?? "/es/gallery",
    file: "/opt/buildhome/repo/pages/gallery.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.name ?? "gallery___it",
    path: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.path ?? "/it/gallery",
    file: "/opt/buildhome/repo/pages/gallery.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.name ?? "gallery___de",
    path: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.path ?? "/de/gallery",
    file: "/opt/buildhome/repo/pages/gallery.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.name ?? "home___en",
    path: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.path ?? "/home",
    file: "/opt/buildhome/repo/pages/home.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47home_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.name ?? "home___fr",
    path: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.path ?? "/fr/home",
    file: "/opt/buildhome/repo/pages/home.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47home_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.name ?? "home___es",
    path: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.path ?? "/es/home",
    file: "/opt/buildhome/repo/pages/home.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47home_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.name ?? "home___it",
    path: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.path ?? "/it/home",
    file: "/opt/buildhome/repo/pages/home.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47home_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.name ?? "home___de",
    path: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.path ?? "/de/home",
    file: "/opt/buildhome/repo/pages/home.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47home_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47home_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index___en",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index___fr",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/fr",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index___es",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/es",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index___it",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/it",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.name ?? "index___de",
    path: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.path ?? "/de",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug___en",
    path: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/news/:slug",
    file: "/opt/buildhome/repo/pages/news/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug___fr",
    path: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/fr/news/:slug",
    file: "/opt/buildhome/repo/pages/news/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug___es",
    path: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/es/news/:slug",
    file: "/opt/buildhome/repo/pages/news/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug___it",
    path: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/it/news/:slug",
    file: "/opt/buildhome/repo/pages/news/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug___de",
    path: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/de/news/:slug",
    file: "/opt/buildhome/repo/pages/news/[slug].vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.name ?? "news___en",
    path: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.path ?? "/news",
    file: "/opt/buildhome/repo/pages/news/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.name ?? "news___fr",
    path: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.path ?? "/fr/news",
    file: "/opt/buildhome/repo/pages/news/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.name ?? "news___es",
    path: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.path ?? "/es/news",
    file: "/opt/buildhome/repo/pages/news/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.name ?? "news___it",
    path: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.path ?? "/it/news",
    file: "/opt/buildhome/repo/pages/news/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.name ?? "news___de",
    path: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.path ?? "/de/news",
    file: "/opt/buildhome/repo/pages/news/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.name ?? "nxtgen___en",
    path: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.path ?? "/nxtgen",
    file: "/opt/buildhome/repo/pages/nxtgen.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/nxtgen.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.name ?? "nxtgen___fr",
    path: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.path ?? "/fr/nxtgen",
    file: "/opt/buildhome/repo/pages/nxtgen.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/nxtgen.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.name ?? "nxtgen___es",
    path: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.path ?? "/es/nxtgen",
    file: "/opt/buildhome/repo/pages/nxtgen.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/nxtgen.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.name ?? "nxtgen___it",
    path: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.path ?? "/it/nxtgen",
    file: "/opt/buildhome/repo/pages/nxtgen.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/nxtgen.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.name ?? "nxtgen___de",
    path: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.path ?? "/de/nxtgen",
    file: "/opt/buildhome/repo/pages/nxtgen.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47nxtgen_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/nxtgen.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.name ?? "playground___en",
    path: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.path ?? "/playground",
    file: "/opt/buildhome/repo/pages/playground.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.name ?? "playground___fr",
    path: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.path ?? "/fr/playground",
    file: "/opt/buildhome/repo/pages/playground.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.name ?? "playground___es",
    path: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.path ?? "/es/playground",
    file: "/opt/buildhome/repo/pages/playground.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.name ?? "playground___it",
    path: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.path ?? "/it/playground",
    file: "/opt/buildhome/repo/pages/playground.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.name ?? "playground___de",
    path: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.path ?? "/de/playground",
    file: "/opt/buildhome/repo/pages/playground.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47playground_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.name ?? "sign-up___en",
    path: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.path ?? "/sign-up",
    file: "/opt/buildhome/repo/pages/sign-up.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.name ?? "sign-up___fr",
    path: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.path ?? "/fr/sign-up",
    file: "/opt/buildhome/repo/pages/sign-up.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.name ?? "sign-up___es",
    path: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.path ?? "/es/sign-up",
    file: "/opt/buildhome/repo/pages/sign-up.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.name ?? "sign-up___it",
    path: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.path ?? "/it/sign-up",
    file: "/opt/buildhome/repo/pages/sign-up.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.name ?? "sign-up___de",
    path: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.path ?? "/de/sign-up",
    file: "/opt/buildhome/repo/pages/sign-up.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47sign_45up_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.name ?? "spacecraft-fleet___en",
    path: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.path ?? "/spacecraft-fleet",
    file: "/opt/buildhome/repo/pages/spacecraft-fleet.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spacecraft-fleet.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.name ?? "spacecraft-fleet___fr",
    path: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.path ?? "/fr/spacecraft-fleet",
    file: "/opt/buildhome/repo/pages/spacecraft-fleet.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spacecraft-fleet.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.name ?? "spacecraft-fleet___es",
    path: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.path ?? "/es/spacecraft-fleet",
    file: "/opt/buildhome/repo/pages/spacecraft-fleet.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spacecraft-fleet.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.name ?? "spacecraft-fleet___it",
    path: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.path ?? "/it/spacecraft-fleet",
    file: "/opt/buildhome/repo/pages/spacecraft-fleet.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spacecraft-fleet.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.name ?? "spacecraft-fleet___de",
    path: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.path ?? "/de/spacecraft-fleet",
    file: "/opt/buildhome/repo/pages/spacecraft-fleet.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spacecraft_45fleet_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spacecraft-fleet.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.name ?? "spaceflight-experience___en",
    path: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.path ?? "/spaceflight-experience",
    file: "/opt/buildhome/repo/pages/spaceflight-experience.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceflight-experience.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.name ?? "spaceflight-experience___fr",
    path: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.path ?? "/fr/spaceflight-experience",
    file: "/opt/buildhome/repo/pages/spaceflight-experience.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceflight-experience.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.name ?? "spaceflight-experience___es",
    path: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.path ?? "/es/spaceflight-experience",
    file: "/opt/buildhome/repo/pages/spaceflight-experience.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceflight-experience.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.name ?? "spaceflight-experience___it",
    path: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.path ?? "/it/spaceflight-experience",
    file: "/opt/buildhome/repo/pages/spaceflight-experience.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceflight-experience.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.name ?? "spaceflight-experience___de",
    path: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.path ?? "/de/spaceflight-experience",
    file: "/opt/buildhome/repo/pages/spaceflight-experience.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceflight_45experience_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceflight-experience.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.name ?? "spaceport-america___en",
    path: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.path ?? "/spaceport-america",
    file: "/opt/buildhome/repo/pages/spaceport-america.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceport-america.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.name ?? "spaceport-america___fr",
    path: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.path ?? "/fr/spaceport-america",
    file: "/opt/buildhome/repo/pages/spaceport-america.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceport-america.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.name ?? "spaceport-america___es",
    path: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.path ?? "/es/spaceport-america",
    file: "/opt/buildhome/repo/pages/spaceport-america.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceport-america.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.name ?? "spaceport-america___it",
    path: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.path ?? "/it/spaceport-america",
    file: "/opt/buildhome/repo/pages/spaceport-america.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceport-america.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.name ?? "spaceport-america___de",
    path: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.path ?? "/de/spaceport-america",
    file: "/opt/buildhome/repo/pages/spaceport-america.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47spaceport_45america_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/spaceport-america.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.name ?? "webgl-sandbox___en",
    path: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.path ?? "/webgl-sandbox",
    file: "/opt/buildhome/repo/pages/webgl-sandbox.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/webgl-sandbox.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.name ?? "webgl-sandbox___fr",
    path: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.path ?? "/fr/webgl-sandbox",
    file: "/opt/buildhome/repo/pages/webgl-sandbox.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/webgl-sandbox.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.name ?? "webgl-sandbox___es",
    path: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.path ?? "/es/webgl-sandbox",
    file: "/opt/buildhome/repo/pages/webgl-sandbox.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/webgl-sandbox.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.name ?? "webgl-sandbox___it",
    path: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.path ?? "/it/webgl-sandbox",
    file: "/opt/buildhome/repo/pages/webgl-sandbox.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/webgl-sandbox.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.name ?? "webgl-sandbox___de",
    path: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.path ?? "/de/webgl-sandbox",
    file: "/opt/buildhome/repo/pages/webgl-sandbox.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.alias || [],
    redirect: _47opt_47buildhome_47repo_47pages_47webgl_45sandbox_46vueMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/webgl-sandbox.vue").then(m => m.default || m)
  }
]