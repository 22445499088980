export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Virgin Galactic is launching a new space age, where all are invited along for the ride."},{"property":"og:title","content":"Virgin Galactic"},{"property":"og:description","content":"Virgin Galactic is launching a new space age, where all are invited along for the ride."},{"property":"og:image","content":"//www.virgingalactic.com/assets/images/share.png"}],"link":[{"rel":"stylesheet","href":"/assets/styles/osano.css","type":"text/css"},{"rel":"preload","href":"/_nuxt/assets/fonts/EuclidGalactic-1G-WebXL.woff2","as":"font","type":"font/woff2"},{"rel":"preload","href":"/_nuxt/assets/fonts/EuclidGalactic-2G-WebXL.woff2","as":"font","type":"font/woff2"},{"rel":"preload","href":"/_nuxt/assets/fonts/EuclidGalactic-3G-WebXL.woff2","as":"font","type":"font/woff2"},{"rel":"preload","href":"/_nuxt/assets/fonts/EuclidGalactic-4G-WebXL.woff2","as":"font","type":"font/woff2"},{"rel":"preload","href":"/_nuxt/assets/fonts/EuclidGalactic-5G-WebXL.woff2","as":"font","type":"font/woff2"}],"style":[],"script":[{"src":"https://js.stripe.com/v3"},{"src":"https://cmp.osano.com/16Bj52TCiDMVA1fnc/849bbfc2-fb10-4389-a497-deba17838192/osano.js"},{"src":"/vendors/modernizr.js"}],"noscript":[],"title":"Virgin Galactic","charset":"utf-8","viewport":"width=device-width, initial-scale=1, maximum-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"